import React from 'react';

import checked from '../assets/checked_icon.png';
import unchecked from '../assets/unchecked_icon.png';

const SelectionItem = ({ name, type, onSelect, value, maxOptionVotesCount }) => {
    return (
        <div 
            style={{ 
                display: 'flex', flexDirection: 'row', width: '75%', justifyContent: 'space-between', alignItems: 'center', 
                marginTop: 20, paddingLeft: '5%', paddingRight: type === "binary" ? '1%' : '4%', backgroundColor: 'white', color: '#00006b', borderRadius: 45,
            }}
            onClick={() => type === "binary" && onSelect(!value)}
        >
            <p style={{ width: '80%' }}><b>{name}</b></p>
            { type === "binary" ?
                // Custom checkbox
                <div style={{ 
                    width: '15%', aspectRatio: '1 / 1', marginTop: '1%', marginBottom: '1%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    borderRadius: 40, backgroundColor: value ? '#0000dc' : '#dcdcdc' 
                }}>
                    { value ? 
                        <img style={{ width: '60%', height: '60%', }} src={checked} alt="Checked" />
                    :
                        <img style={{ width: '50%', height: '50%', }} src={unchecked} alt="Unchecked" />
                    }
                </div>
            :
                <select style={{ width: 50, height: 40, fontSize: 16, paddingLeft: 6 }} value={value} onChange={onSelect}>
                    {[...Array(maxOptionVotesCount + 1)].map((_, i) => <option key={i} value={i} >{i}</option>) }
                </select> }
        </div>
    )
}

export default SelectionItem;