import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import VotingSettings from '../components/VotingSettings';
import VotingInfo from '../components/VotingInfo';

const Admin = () => {

    const [votings, setVotings] = useState();
    const [votingIsSetting, setVotingIsSetting] = useState(false);
    const [jwt, setJwt] = useState(null);
    const [adminUser, setAdminUser] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [totalToDelete, setTotalToDelete] = useState(0);
    const [currentlyDeleted, setCurrentlyDeleted] = useState(0);

    const [identifier, setIdentifier] = useState(null);
    const [password, setPassword] = useState(null);
    const [rememberPassword, setRememberPassword] = useState(false);

    const login = async () => {
        const response = await fetch(process.env.REACT_APP_STRAPI_URL + '/api/auth/local', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                identifier: identifier,
                password: password,
            })
        }).catch(err => console.log(err));

        if (response.ok) {
            const { jwt, user } = await response.json();
            setJwt(jwt);
            setAdminUser(user);
            fetchVotings();

            const auth = { jwt: jwt, user: user }
            if (rememberPassword) {
                localStorage.setItem("auth", JSON.stringify(auth));
            } else {
                sessionStorage.setItem("auth", JSON.stringify(auth));
            }

            setIdentifier(null);
            setPassword(null);
            setRememberPassword(false);
        } else {
            alert("Incorrect login credentials!")
        }
    }

    const logout = () => {
        setJwt(null);
        setAdminUser(null);
        localStorage.removeItem("auth");
        sessionStorage.removeItem("auth");
    }

    const fetchVotings = async () => {
        // Load existing votings
        const { data } = await fetch(process.env.REACT_APP_STRAPI_URL + '/api/votings?sort=id&pagination[pageSize]=100').then(response => response.json());
        setVotings(data);
    };

    const addToVotings = (item) => {
        setVotings(votings.concat(item));
    }

    const removeFromVotings = (index) => {
        setVotings(votings.filter((_, i) => i !== index));
    }

    const updateVotings = (index, newItem) => {
        setVotings(votings.map((originalItem, i) => i === index ? newItem : originalItem ));
    }

    useEffect(() => {
        document.title = "EVS LEMMA admin page";

        let auth = JSON.parse(localStorage.getItem("auth")) || JSON.parse(sessionStorage.getItem("auth"));
        if (auth) {
            setJwt(auth.jwt);
            setAdminUser(auth.user);
            fetchVotings();
        }
    }, []);
  
    return (
        <div>
            <h2>
                LEMMA Electronic voting system admin page
            </h2>

            { jwt ? 
                votingIsSetting ?
                    <VotingSettings addToVotings={addToVotings} setVotingIsSetting={setVotingIsSetting} jwt={jwt}/>
                : isDeleting ?
                    <div>
                        <p>Voting is being deleted. Please wait.</p>
                        <p>{currentlyDeleted}/{totalToDelete}</p>
                    </div>
                : !votings ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ReactLoading type={'spin'} color={'lightgray'} height={100} width={100} />
                    </div>
                :
                    <div>
                        <h3>Existing votings:</h3>
                            { votings.map((item, i) => {
                                return <VotingInfo 
                                    voting={item} index={i} removeFromVotings={removeFromVotings} updateVotings={updateVotings} 
                                    setIsDeleting={setIsDeleting} setTotalToDelete={setTotalToDelete} setCurrentlyDeleted={setCurrentlyDeleted} jwt={jwt} key={i} 
                                />
                            }) }
                            <button className="Admin-panel-button" onClick={() => setVotingIsSetting(true)}>
                                Create new voting
                            </button>
                        <hr/>
                        <p>Logged in as: {adminUser.username}</p>
                        <button className="Admin-panel-button" style={{ marginTop: 0 }}onClick={logout}>
                            Log out
                        </button>
                    </div>
            :
                // login form
                <div style={{ border: '4px solid lightgray', borderRadius: 50 }}>
                    <h3>Enter your login credentials:</h3>
                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
                        Username or email:
                        <input style={{ marginLeft: 10 }} id='email' onChange={e => setIdentifier(e.target.value)}/>
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
                        Password:
                        <input style={{ marginLeft: 10 }} type='password' id='password' onChange={e => setPassword(e.target.value)}/>
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
                        Remember password:
                        <input style={{ marginLeft: 10, width: 20, height: 20 }} type='checkbox' onChange={e => setRememberPassword(e.target.checked)}/>
                    </label>
                    <button className="Admin-panel-button" onClick={login}>
                        Log in
                    </button>
                </div>
            }
        </div>
    );
}
  
export default Admin;