import React from 'react';
import { useState } from 'react';
import QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';

import { RobotoRegular } from '../assets/RobotoRegular';

const VotingSettings = ({ addToVotings, setVotingIsSetting, jwt }) => {
    
    const [votingName, SetVotingName] = useState();
    const [votingType, SetVotingType] = useState("binary");
    const [maxVotesCount, setMaxVotesCount] = useState();
    const [maxOptionVotesCount, setMaxOptionVotesCount] = useState(10);
    const [votersCount, setVotersCount] = useState(1000);

    const [isLoading, setIsLoading] = useState(false);
    const [currentlyLoaded, setCurrentlyLoaded] = useState(0);

    const createVoting = async () => {
        // Check input
        if (!votingName?.trim()) {
            alert("Voting name must not be empty.");
            return;
        }
        if (votingType === "multiple votes" && maxVotesCount > 0 && maxOptionVotesCount > maxVotesCount) {
            alert("Maximal votes count per film cannot be greater than maximal total votes count.");
            return;
        }
        if (votersCount > 5000) {
            alert("Maximal possible voters count is 5000.");
            return;
        }
        if (votersCount < 1 || maxVotesCount < 0 || maxOptionVotesCount < 1) {
            alert("Entered numeric values are invalid.");
            return;
        }

        setCurrentlyLoaded(0);
        setIsLoading(true);

        // Create voting
        const response = await fetch(process.env.REACT_APP_STRAPI_URL + '/api/votings', {
            method: 'POST',
            headers: { 
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                data: { 
                    'name': votingName,
                    'type': votingType,
                    'maxVotesCount': maxVotesCount > 0 ? maxVotesCount : null,
                    'maxOptionVotesCount': votingType === "multiple votes" ? maxOptionVotesCount : null,
                    'votersCount': votersCount,
                }
            })
        }).catch(err => console.log(err));

        if(response.ok) {
            const { data } = await response.json();

            await generateQrCodes(data);

            // Add new voting to the votings list
            addToVotings(data);
            
            setVotingIsSetting(false);
        } else {
            alert("An error occurred while creating the voting. Please try again.");
        }
        setIsLoading(false);
    }

    const generateQrCodes = async (voting) => {
        const pdf = new jsPDF();
        pdf.addFileToVFS('RobotoRegular.ttf', RobotoRegular);
        pdf.addFont('RobotoRegular.ttf', 'RobotoRegular', 'normal');
        pdf.setFont('RobotoRegular');
        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;

        for (let i = 0; i < votersCount; i++) {
            // Generate random unique voterCode
            const voterCode = uuidv4();
            let voterId;
            let votingUrl = window.location.origin;

            // Save voter with voterCode to Strapi database
            const response = await fetch(process.env.REACT_APP_STRAPI_URL + '/api/voters', {
                method: 'POST',
                headers: { 
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    data: { 'voterCode': voterCode, 'voting': voting.id }
                })
            }).catch(err => console.log(err));
            
            if(response.ok) {
                // Add votingId, voterId and voterCode to the votingUrl
                const { data } = await response.json();
                voterId = data.id;
                votingUrl += '?votingId=' + voting.id + '&voterId=' + voterId + '&voterCode=' + voterCode;
            } else {
                alert("An error occurred while creating the voters, QR codes could not be generated. However, some voters could be created in the Strapi database. Check the database and delete them if necessary.");
                setIsLoading(false);
                return;
            }

            // Add new page to pdf if needed
            if (i % 4 === 0 && i !== 0) {
                pdf.addPage();
            }

            // Generate QR code for the votingUrl
            const qrCode = await QRCode.toDataURL(votingUrl);
            // Calculate the position of the QR code on the page
            const qrCodeSize = 85;
            const xPos = (pageWidth / 2 - qrCodeSize) / 2 + ((i % 2) ? pageWidth / 2 : 0);
            const yPos = (pageHeight / 2 - qrCodeSize) / 2 - 12 + ((i % 4) < 2 ? 0 : pageHeight / 2);
            // Add QR code to the pdf file
            pdf.addImage(qrCode, 'PNG', xPos, yPos, qrCodeSize, qrCodeSize);

            // Add texts to the pdf file
            pdf.setTextColor('black');
            pdf.setFontSize(20);
            pdf.text("Váš unikátní hlasovací link:", xPos + qrCodeSize / 2, yPos - 8, { align: 'center' });
            pdf.setFontSize(14);
            pdf.text("Hlasování se otevře po 2. soutěžním bloku.", ((i % 2) ? pageWidth / 2 : 0) + 5, yPos + qrCodeSize + 4, { align: 'left' });
            pdf.setFontSize(16);
            pdf.text("Festivalová WiFi:", xPos + qrCodeSize / 2, yPos + qrCodeSize + 21, { align: 'center' });
            pdf.setFontSize(12);
            pdf.text("SSID: FFFIMU2024", xPos + qrCodeSize / 2, yPos + qrCodeSize + 28, { align: 'center' });
            pdf.text("Password: festival24", xPos + qrCodeSize / 2, yPos + qrCodeSize + 34, { align: 'center' });
            pdf.setFontSize(16);
            pdf.setTextColor('gray');
            pdf.text("Your unique voting link:", xPos + qrCodeSize / 2, yPos, { align: 'center' });
            pdf.setFontSize(12);
            pdf.text("Voting opens after the 2nd block.", ((i % 2) ? pageWidth / 2 : 0) + 5, yPos + qrCodeSize + 10, { align: 'left' });
            pdf.setFontSize(8);
            pdf.text("Voting: " + voting.attributes?.name, ((i % 2) ? pageWidth / 2 : 0) + 3, ((i % 4) < 2 ? pageHeight / 2 : pageHeight) - 3);
            pdf.text("Voter ID: " + voterId, ((i % 2) ? pageWidth : pageWidth / 2) - 3, ((i % 4) < 2 ? pageHeight / 2 : pageHeight) - 3, { align: 'right' });

            // Add cutting lines to the page of the pdf file
            if (i % 4 === 3 || i === votersCount - 1) {
                // horizontal lines
                pdf.line(0, pageHeight / 2, 5, pageHeight / 2);
                pdf.line(pageWidth / 2 - 5, pageHeight / 2, pageWidth / 2 + 5, pageHeight / 2);
                pdf.line(pageWidth - 5, pageHeight / 2, pageWidth, pageHeight / 2);
                // vertical lines
                pdf.line(pageWidth / 2, 0, pageWidth / 2, 5);
                pdf.line(pageWidth / 2, pageHeight / 2 - 5, pageWidth / 2, pageHeight / 2 + 5);
                pdf.line(pageWidth / 2, pageHeight - 5, pageWidth / 2, pageHeight);
            }

            setCurrentlyLoaded(i + 1);
        }

        // Download the pdf file
        pdf.save('voting_qrcodes.pdf');
    }

    return (
        isLoading ?
            <div>
                <p>Voting creating and QR codes generating is in progress. Please wait.</p>
                <p>{currentlyLoaded}/{votersCount}</p>
            </div>
        : 
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '4px solid lightgray', borderRadius: 50 }}>
                <h3>Create new voting:</h3>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 10 }}>
                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5}}>
                        Voting name:
                        <input style={{ marginLeft: 10 }} onChange={e => SetVotingName(e.target.value)} placeholder="mandatory item"/>
                    </label>

                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                        Voting type:
                        <select style={{ height: 25, fontSize: 14, marginLeft: 10 }} value={votingType} onChange={ e => SetVotingType(e.target.value) }>
                            <option value={"binary"} >{"binary"}</option>
                            <option value={"multiple votes"} >{"multiple votes"}</option>
                        </select>
                    </label>

                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                        Maximal total votes count:
                        <input
                            style={{ marginLeft: 10 }}
                            onChange={e => setMaxVotesCount(Math.floor(e.target.value))}
                            type="number"
                            min={1}
                        />
                    </label>

                    { votingType === "multiple votes" && <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                        Maximal votes count per film:
                        <input
                            value={maxOptionVotesCount}
                            style={{ marginLeft: 10 }}
                            onChange={e => setMaxOptionVotesCount(Math.floor(e.target.value))}
                            type="number"
                            min={1}
                        />
                    </label> }

                    <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                        Voters count:
                        <input
                            value={votersCount}
                            style={{ marginLeft: 10 }}
                            onChange={e => setVotersCount(Math.floor(e.target.value))}
                            type="number"
                            min={1}
                            max={5000}
                        />
                    </label>
                </div>
                
                <div>
                    <button className="Admin-panel-button" onClick={() => setVotingIsSetting(false)}>
                        Cancel
                    </button>
                    <button className="Admin-panel-button" onClick={createVoting}>
                        Create voting
                    </button>
                </div>
            </div>
    )
}

export default VotingSettings;