import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import VotingList from '../components/VotingList';
import VotingResult from '../components/VotingResult';

import muniLogo from '../assets/muni_logo.png';

const Voting = () => {

    const [voting, setVoting] = useState();
    const [votingPermitted, setVotingPermitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [voterId, setVoterId] = useState();
    const [voterCode, setVoterCode] = useState();

    useEffect(() => {
        const fetchVoting = async (votingId) => {
            const { data } = await fetch(process.env.REACT_APP_STRAPI_URL + `/api/votings/${votingId}`).then(response => response.json());
            return data;
        }

        let liveUpdateInterval = null;

        const fetchAPIData = async () => {
            const params = new URLSearchParams(window.location.search);
            const votingId = params.get('votingId');
            const voterId = params.get('voterId');
            const voterCode = params.get('voterCode');

            // Get voting
            const voting = await fetchVoting(votingId);
            setVoting(voting);

            // Set page title
            if (voting?.attributes?.name) {
                document.title = voting.attributes.name + " - EVS LEMMA";
            }

            // Voting state live update when voting is ready
            if (voting?.attributes?.state === "ready") {
                liveUpdateInterval = setInterval(async () => { 
                    const updatedVoting = await fetchVoting(votingId);

                    // Voting state has changed
                    if (updatedVoting?.attributes?.state !== "ready") {
                        setVoting(updatedVoting);
                        clearInterval(liveUpdateInterval);
                    }

                }, 10000);
            }

            // Check if voting with requested id exists, voterId and voterCode from params is assigned to a valid voter and if this voter has already voted or not
            if (voting && voterId && voterCode) {
                setVoterId(voterId);
                setVoterCode(voterCode);
                const { votingPermitted } = await fetch(process.env.REACT_APP_BACKEND_URL + '/voting/verifyVoter', { 
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'votingId': voting.id, 'voterId': voterId, 'voterCode': voterCode, })
                }).then(response => response.json());
                setVotingPermitted(votingPermitted);
            } else {
                setVotingPermitted(false);
            }

            setIsLoading(false);
        };

        fetchAPIData();

        return () => {
            if (liveUpdateInterval) {
                clearInterval(liveUpdateInterval);
            }
        };
    }, []);

    return (
        <div style={{ flex: 1, alignItems: 'center', width: 700, maxWidth: 'calc(100vw - 60px)', padding: 30, backgroundColor: '#0000dc', color: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 15 }}>
                <img style={{ width: 50, height: 62, }} src={muniLogo} alt="Logo" />
                <h2 style={{ fontSize: 'calc(10px + 2vmin)', marginLeft: 20 }}>
                    LEMMA Electronic voting system
                </h2>
            </div>
            <hr style={{ backgroundColor: 'white', height: 3, border: 0 }} />

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
                { isLoading ?
                    <ReactLoading type={'spin'} height={100} width={100} />
                : voting ? 
                    voting.attributes?.state === "ongoing" ? 
                        votingPermitted ? 
                            <VotingList voting={voting} voterId={voterId} voterCode={voterCode}/> 
                        : 
                            <p>Your voting link has already been used. Please wait for the voting results.</p>
                    : voting.attributes?.state === "closed" ?
                        voting.attributes?.publicResults ?
                            <VotingResult voting={voting}/>
                        :
                            <p>Voting <b>{voting.attributes?.name}</b> has been closed.</p>
                    :
                        <p>Please wait for the voting to start.</p>
                :
                    <p>Requested voting does not exist.</p>
                }
            </div>
        </div>
    );
}

export default Voting;