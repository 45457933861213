import React from 'react';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

const VotingResult = ({ voting, backgroundColor = 'white', color = '#00006b' }) => {
    
    const [filmsList, setFilmsList] = useState(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + `/voting/results/${voting.id}`).then(response => response.json()).then(data => setFilmsList(data.data));
    }, [voting]);

    return (
        filmsList ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p>
                    Results of the voting <b>{voting.attributes?.name}</b>:
                </p>
                <table style={{ width: '100%', maxWidth: 600, padding: 20, backgroundColor: backgroundColor, color: color, borderRadius: 40 }}>
                    <tbody>
                        <tr>
                            <th style={{ width: '65%' }}>Film name</th>
                            <th style={{ width: '35%' }}>Votes count</th>
                        </tr>
                        <tr><td colSpan="2" style={{ height: 10 }} /></tr>
                        { filmsList
                            .sort((a, b) => b.attributes?.votesCount - a.attributes?.votesCount)
                            .map((item, i) => {
                                return <tr key={i}>
                                    <td>{item.attributes?.Name}</td>
                                    <td>{item.attributes?.votesCount}</td>
                                </tr>
                        }) }
                    </tbody>
                </table>
            </div>
        :
            <ReactLoading type={'spin'} color={backgroundColor} height={100} width={100} />
    )
}

export default VotingResult;