import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import './App.css';

import Voting from './pages/Voting';
import Admin from './pages/Admin';

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div className="App-content">
          <Routes>
            <Route path="/" element={<Voting />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
