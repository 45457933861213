import React from 'react';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactLoading from 'react-loading';
import platform from 'platform';

import SelectionItem from './SelectionItem';

const VotingList = ({ voting, voterId, voterCode }) => {

    const [filmsList, setFilmsList] = useState([]);

    const [voted, setVoted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchFilms = async () => {
            // If exists, load filmList with already filled votes for this voting
            const filmsList = localStorage.getItem(`filmsList ${voting.id}`);
            if (filmsList) {
                setFilmsList(JSON.parse(filmsList));
            } else {
                const { data } = await fetch(
                    process.env.REACT_APP_STRAPI_URL + '/api/films?fields[0]=Name&filters[Block][$nei]=Bonus&sort=Index&pagination[pageSize]=100'
                ).then(response => response.json());
                setFilmsList(data);
            }

            setIsLoading(false);
        }

        fetchFilms();
    }, [voting]);

    const updateFilmListItem = (index, attribute, value) => {
        const updatedFilmList = filmsList.map((film, i) => i === index ? {...film, [attribute]: value} : film);
        setFilmsList(updatedFilmList);
        localStorage.setItem(`filmsList ${voting.id}`, JSON.stringify(updatedFilmList));
    }

    const vote = () => {
        let votes;
        let totalVotesCount = 0;
        if (voting.attributes?.type === "binary") {
            votes = filmsList.filter(item => item.selected).map(item => { return { 'id': item.id } });
            totalVotesCount = votes.length;
        } else {
            votes = filmsList.filter(item => item.votesCount).map(item => {
                totalVotesCount += item.votesCount; 
                return { 'id': item.id, 'votesCount': item.votesCount } 
            });
        }

        // Check if total votesCount is in allowed range
        if (voting.attributes?.maxVotesCount && totalVotesCount > voting.attributes?.maxVotesCount) {
            alert(`You can give a maximum of ${voting.attributes?.maxVotesCount} votes in total.`);
            return;
        };

        // Use native browser confirm dialog on mobile devices
        if (platform.os.family === 'Android' || platform.os.family === 'iOS') {
            const confirmed = window.confirm("Do you really want to vote for the selected films? Voting cannot be repeated.");
            if (confirmed) {
                sendVoting(votes);
            }
        } else {
            confirmAlert({
                title: 'Confirm voting',
                message: 'Do you really want to vote for the selected films? Voting cannot be repeated.',
                buttons: [
                    { label: 'Yes', onClick: () => sendVoting(votes) },
                    { label: 'No' }
                ]
            });
        };
    };

    // Send votes to database
    const sendVoting = async (votes) => {
        setIsLoading(true);

        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/voting/vote', { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'votingId': voting.id,
                'voterId': voterId,
                'voterCode': voterCode,
                votes
            })
        }).catch(err => console.log(err));

        if (response.ok) {
            setVoted(true);
            localStorage.removeItem(`filmsList ${voting.id}`);
        } else {
            window.location.reload();
            alert("An error occurred while processing your vote.");
        }
        setIsLoading(false);
    }

    return (
        isLoading ? <ReactLoading type={'spin'} height={100} width={100} />
        : voted ? <p>Your votes have been saved. Please wait for the voting results.</p>
        : <div style={{ fontSize: 'calc(6px + 2vmin)', textAlign: 'left' }} >
            <p style={{ marginTop: 10 }}>
                Hello, welcome to the voting <b>{voting.attributes?.name}</b>. Please select the films you wish to vote for below.
            </p>
            { voting.attributes?.type === "multiple votes" && <p>
                You can give each film 0 to {voting.attributes?.maxOptionVotesCount} votes.
            </p> }
            { voting.attributes?.maxVotesCount && <p>
                You can give up to {voting.attributes?.maxVotesCount} votes in total.
            </p> }
            <hr style={{ backgroundColor: 'white', height: 3, border: 0 }} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 30 }}>
                { filmsList.map((item, i) => {
                    return voting.attributes?.type === "binary" ?
                        <SelectionItem name={item.attributes?.Name} type={voting.attributes?.type} value={item.selected ? item.selected : false} 
                            onSelect={ checked => updateFilmListItem(i, 'selected', checked) }
                            key={i}
                        />
                    :
                        <SelectionItem name={item.attributes?.Name} type={voting.attributes?.type} value={item.votesCount} 
                            maxOptionVotesCount={voting.attributes?.maxOptionVotesCount}
                            onSelect={ e => updateFilmListItem(i, 'votesCount', parseInt(e.target.value)) }
                            key={i}
                        />
                }) }
            </div>
            
            <hr style={{ backgroundColor: 'white', height: 3, border: 0 }} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <button className="Vote-button" onClick={vote}>
                    <b>Confirm voting</b>
                </button>
            </div>
        </div>
    )
}

export default VotingList;