import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import VotingResult from './VotingResult';

const VotingInfo = ({ voting, index, removeFromVotings, updateVotings, setIsDeleting, setTotalToDelete, setCurrentlyDeleted, jwt }) => {

    const [showResults, setShowResults] = useState(false);

    const deleteVoting = () => {
        confirmAlert({
            title: 'Delete voting',
            message: 'Do you really want to completely delete selected voting with all its data from the database?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let deleteIndex = 0;
                        setCurrentlyDeleted(0);
                        setTotalToDelete('?');
                        setIsDeleting(true);

                        // Get all votes and voters of this voting from database
                        const { data } = await fetch(process.env.REACT_APP_STRAPI_URL + `/api/votings/${voting.id}?populate[votes][fields][0]=id&populate[voters][fields][0]=id`, {
                            headers: { 
                                Authorization: `Bearer ${jwt}`,
                            },
                        }).then(response => response.json());
                        const votes = data?.attributes?.votes?.data;
                        const voters = data?.attributes?.voters?.data;
                        setTotalToDelete(votes.length + voters.length);

                        // Iterate through all votes and delete them
                        for (const vote of votes) {
                            const response = await fetch(process.env.REACT_APP_STRAPI_URL + `/api/votes/${vote.id}`, {
                                method: 'DELETE',
                                headers: { 
                                    Authorization: `Bearer ${jwt}`,
                                },
                            }).catch(err => console.log(err));
                    
                            if(!response.ok) {
                                alert("An error occurred while deleting the voting. Please try again.");
                                setIsDeleting(false);
                                return;
                            }
                            deleteIndex += 1;
                            setCurrentlyDeleted(deleteIndex);
                        }

                        // Iterate through all voters and delete them
                        for (const voter of voters) {
                            const response = await fetch(process.env.REACT_APP_STRAPI_URL + `/api/voters/${voter.id}`, {
                                method: 'DELETE',
                                headers: { 
                                    Authorization: `Bearer ${jwt}`,
                                },
                            }).catch(err => console.log(err));
                    
                            if(!response.ok) {
                                alert("An error occurred while deleting the voting. Please try again.");
                                setIsDeleting(false);
                                return;
                            }
                            deleteIndex += 1;
                            setCurrentlyDeleted(deleteIndex);
                        }

                        // Delete this voting from database
                        const response = await fetch(process.env.REACT_APP_STRAPI_URL + `/api/votings/${voting.id}`, {
                            method: 'DELETE',
                            headers: { 
                                Authorization: `Bearer ${jwt}`,
                            },
                        }).catch(err => console.log(err));
                
                        if (response.ok) {
                            // Remove from the votings list
                            removeFromVotings(index);
                        } else {
                            alert("An error occurred while deleting the voting. Please try again.");
                        }
                        setIsDeleting(false);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const updateVoting = async (data) => {
        const response = await fetch(process.env.REACT_APP_STRAPI_URL + `/api/votings/${voting.id}`, {
            method: 'PUT',
            headers: { 
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                data: data
            })
        }).catch(err => console.log(err));

        if(response.ok) {
            const { data } = await response.json();

            // Update the votings list
            updateVotings(index, data)
        } else {
            alert("An error occurred while editing the voting. Please try again.");
        }
    }

    const closeVoting = () => {
        confirmAlert({
            title: 'Close voting',
            message: 'You really want to close selected voting? Voting will not be able to be restarted.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => updateVoting({ state: "closed" })
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    return (
        <div style={{ border: '4px solid lightgray', borderRadius: 50, marginBottom: 20 }}>
            <h2>{voting.attributes?.name}</h2>
            { showResults ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <VotingResult voting={voting} backgroundColor='lightgray' color='black' />
                    <button className="Admin-panel-button" style={{ marginTop: 30 }} onClick={() => setShowResults(false)}>
                        Close results
                    </button>
                </div>
            :
                <div>
                    <p>Type: {voting.attributes?.type}</p>
                    <p>Voters count: {voting.attributes?.votersCount}</p>
                    { voting.attributes?.maxVotesCount && <p>Maximal total votes count: {voting.attributes?.maxVotesCount}</p> }
                    { voting.attributes?.maxOptionVotesCount && <p>Maximal votes count per film: {voting.attributes?.maxOptionVotesCount}</p> }
                    <p>State: {' '} 
                        <span style={{ 
                            color: voting.attributes?.state === "ready" ? 
                                'orange' 
                            : voting.attributes?.state === "ongoing" ? 
                                'green'
                            : // state closed
                                'brown'
                            }}>
                                {voting.attributes?.state}
                        </span>
                    </p>

                    { voting.attributes?.state === "ready" ?
                        <div>
                            <button className="Admin-panel-button" onClick={deleteVoting}>
                                Delete
                            </button>
                            <button className="Admin-panel-button" onClick={() => updateVoting({ state: "ongoing" })}>
                                Start
                            </button>
                        </div>
                    : voting.attributes?.state === "ongoing" ?
                        <div>
                            <button className="Admin-panel-button" onClick={() => updateVoting({ state: "ready" })}>
                                Pause
                            </button>
                            <button className="Admin-panel-button" onClick={closeVoting}>
                                Close
                            </button>
                        </div>
                    : // state closed
                        <div>
                            <p>Results published: {' '} 
                                <span style={{ color: voting.attributes?.publicResults ? 'green' : 'brown' }}>
                                    {voting.attributes?.publicResults.toString()}
                                </span>
                            </p>
                            <button className="Admin-panel-button" onClick={deleteVoting}>
                                Delete
                            </button>
                            <button className="Admin-panel-button" onClick={() => updateVoting({ publicResults: !voting.attributes?.publicResults })}>
                                { voting.attributes?.publicResults ? "Cancel publication" : "Publish results" }
                            </button>
                            <button className="Admin-panel-button" onClick={() => setShowResults(true)}>
                                Show results
                            </button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default VotingInfo;